<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <inline-svg
            src="media/svg/misc/dashboard.svg"
            class="mr-5 mt-1 menu-icon"
            style="flex: 0 0 20px"
          ></inline-svg>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/fund/submission'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <inline-svg
          class="mr-5 mt-1 menu-icon"
          style="flex: 0 0 20px"
          src="media/svg/misc/report-dana.svg"
        ></inline-svg>
        <span class="menu-text">Pengajuan Dana Darurat</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/fund/submission/pending"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengajuan Masuk</span>
              </a>
            </li>
          </router-link>
        </ul>
        <ul class="menu-subnav">
          <router-link
            to="/fund/submission/processing"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengajuan Diproses</span>
              </a>
            </li>
          </router-link>
        </ul>
        <ul class="menu-subnav">
          <router-link
            to="/fund/submission/transferred"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengajuan Ditransfer</span>
              </a>
            </li>
          </router-link>
        </ul>
        <ul class="menu-subnav">
          <router-link
            to="/fund/submission/canceled"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengajuan Ditolak</span>
              </a>
            </li>
          </router-link>
        </ul>
        <ul class="menu-subnav">
          <router-link
            to="/fund/submission/finished"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengajuan Selesai</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <!-- <router-link
      to="/report-dana"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <inline-svg
            src="media/svg/misc/report-dana.svg"
            class="mr-5 mt-1 menu-icon"
            style="flex: 0 0 20px;"
          ></inline-svg>
          <span class="menu-text">Pengajuan Dana Darurat</span>
        </a>
      </li>
    </router-link> -->
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/gears/submission'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <inline-svg
          class="mr-5 mt-1 menu-icon"
          style="flex: 0 0 20px"
          src="media/svg/misc/report-gears.svg"
        ></inline-svg>
        <span class="menu-text">Pengajuan TDP Gears</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/gears/submission/pending"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengajuan Masuk</span>
              </a>
            </li>
          </router-link>
        </ul>
        <ul class="menu-subnav">
          <router-link
            to="/gears/submission/processing"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengajuan Diproses</span>
              </a>
            </li>
          </router-link>
        </ul>
        <ul class="menu-subnav">
          <router-link
            to="/gears/submission/delivered"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengajuan Dikirim</span>
              </a>
            </li>
          </router-link>
        </ul>
        <ul class="menu-subnav">
          <router-link
            to="/gears/submission/canceled"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengajuan Ditolak</span>
              </a>
            </li>
          </router-link>
        </ul>
        <ul class="menu-subnav">
          <router-link
            to="/gears/submission/finished"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Pengajuan Selesai</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <!-- <router-link
      to="/gears/submission"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <inline-svg
            class="mr-5 mt-1 menu-icon"
            style="flex: 0 0 20px"
            src="media/svg/misc/report-gears.svg"
          ></inline-svg>
          <span class="menu-text">Pengajuan TDP Gears</span>
        </a>
      </li>
    </router-link> -->

    <!-- <router-link
      to="/builder"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-expand"></i>
          <span class="menu-text">Layout Builder</span>
        </a>
      </li>
    </router-link> -->

    <li class="menu-section">
      <h4 class="menu-text">Manage</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      to="/employee"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <inline-svg
            class="mr-5 mt-1 menu-icon"
            style="flex: 0 0 20px"
            src="media/svg/misc/karyawan.svg"
          ></inline-svg>
          <span class="menu-text">Karyawan</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/fund/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <inline-svg
            class="mr-5 mt-1 menu-icon"
            style="flex: 0 0 20px"
            src="media/svg/misc/dana.svg"
          ></inline-svg>
          <span class="menu-text">Dana Darurat</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/gears/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <inline-svg
            class="mr-5 mt-1 menu-icon"
            style="flex: 0 0 20px"
            src="media/svg/misc/report-gears.svg"
          ></inline-svg>
          <span class="menu-text">TDP Gears</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/information"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <inline-svg
            class="mr-5 mt-1 menu-icon"
            style="flex: 0 0 20px"
            src="media/svg/misc/informasi.svg"
          ></inline-svg>
          <span class="menu-text">Informasi</span>
        </a>
      </li>
    </router-link>
    <li
      class="menu-section"
      v-if="permission.some((item) => item.name === 'can_view_list_admin')"
    >
      <h4 class="menu-text">Admin</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <!-- <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/master'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon-folder-1"></i>
        <span class="menu-text">Master</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            to="/master/service"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Service</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/bank"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Bank</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/lvl-jabatan"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Level Jabatan</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/jabatan"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Jabatan</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/divisi"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Divisi</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/inisiasi"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Inisiasi</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/cabang"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Cabang</span>
              </a>
            </li>
          </router-link>
          <router-link
            to="/master/site-group"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">Site Group</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li> -->
    <!-- <router-link
      to="/user"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-user-add"></i>
          <span class="menu-text">User</span>
        </a>
      </li>
    </router-link> -->
    <router-link to="/tnc" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <inline-svg
            class="mr-5 mt-1 menu-icon"
            style="flex: 0 0 20px"
            src="media/svg/misc/snk.svg"
          ></inline-svg>
          <span class="menu-text">Syarat & Ketentuan</span>
        </a>
      </li>
    </router-link>
    <!-- <router-link
      to="/brodcast"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="permission.some((item) => item.name === 'can_view_list_admin')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <inline-svg
            class="mr-5 mt-1 menu-icon"
            style="flex: 0 0 20px"
            src="media/svg/misc/user.svg"
          ></inline-svg>
          <span class="menu-text">User</span>
        </a>
      </li>
    </router-link> -->
    <!-- <router-link
      to="/role"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="permission.some((item) => item.name === 'can_view_list_role')"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-user-add"></i>
          <span class="menu-text">Role</span>
        </a>
      </li>
    </router-link> -->
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTMenu",
  data() {
    return {
      listMenu: [
        {
          name: "Dasboard",
          route: "/dashboard",
          icon: "menu-icon flaticon2-architecture-and-city",
          children: [],
        },
        {
          name: "Layout Builder",
          route: "/builder",
          icon: "menu-icon flaticon2-expand",
          children: [],
        },
        {
          name: "Report",
          route: "",
          icon: "menu-icon flaticon2-graph",
          children: [
            {
              name: "Absensi",
              route: "/report/absensi",
              icon: "menu-bullet menu-bullet-dot",
            },
            {
              name: "Harian",
              route: "/report/harian",
              icon: "menu-bullet menu-bullet-dot",
            },
            {
              name: "Kejadian",
              route: "/report/kejadian",
              icon: "menu-bullet menu-bullet-dot",
            },
            {
              name: "Patrol",
              route: "/report/patroli",
              icon: "menu-bullet menu-bullet-dot",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
  fill: #3699ff;
}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
  fill: #494b74;
}
</style>
