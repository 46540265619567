var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('inline-svg',{staticClass:"mr-5 mt-1 menu-icon",staticStyle:{"flex":"0 0 20px"},attrs:{"src":"media/svg/misc/dashboard.svg"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])],1)])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/fund/submission'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('inline-svg',{staticClass:"mr-5 mt-1 menu-icon",staticStyle:{"flex":"0 0 20px"},attrs:{"src":"media/svg/misc/report-dana.svg"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan Dana Darurat")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/fund/submission/pending"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan Masuk")])])])]}}])})],1),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/fund/submission/processing"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan Diproses")])])])]}}])})],1),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/fund/submission/transferred"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan Ditransfer")])])])]}}])})],1),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/fund/submission/canceled"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan Ditolak")])])])]}}])})],1),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/fund/submission/finished"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan Selesai")])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/gears/submission'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('inline-svg',{staticClass:"mr-5 mt-1 menu-icon",staticStyle:{"flex":"0 0 20px"},attrs:{"src":"media/svg/misc/report-gears.svg"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan TDP Gears")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/gears/submission/pending"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan Masuk")])])])]}}])})],1),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/gears/submission/processing"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan Diproses")])])])]}}])})],1),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/gears/submission/delivered"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan Dikirim")])])])]}}])})],1),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/gears/submission/canceled"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan Ditolak")])])])]}}])})],1),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/gears/submission/finished"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Pengajuan Selesai")])])])]}}])})],1)])]),_vm._m(0),_c('router-link',{attrs:{"to":"/employee"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('inline-svg',{staticClass:"mr-5 mt-1 menu-icon",staticStyle:{"flex":"0 0 20px"},attrs:{"src":"media/svg/misc/karyawan.svg"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Karyawan")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/fund/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('inline-svg',{staticClass:"mr-5 mt-1 menu-icon",staticStyle:{"flex":"0 0 20px"},attrs:{"src":"media/svg/misc/dana.svg"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Dana Darurat")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/gears/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('inline-svg',{staticClass:"mr-5 mt-1 menu-icon",staticStyle:{"flex":"0 0 20px"},attrs:{"src":"media/svg/misc/report-gears.svg"}}),_c('span',{staticClass:"menu-text"},[_vm._v("TDP Gears")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/information"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('inline-svg',{staticClass:"mr-5 mt-1 menu-icon",staticStyle:{"flex":"0 0 20px"},attrs:{"src":"media/svg/misc/informasi.svg"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Informasi")])],1)])]}}])}),(_vm.permission.some((item) => item.name === 'can_view_list_admin'))?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Admin")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),_c('router-link',{attrs:{"to":"/tnc"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('inline-svg',{staticClass:"mr-5 mt-1 menu-icon",staticStyle:{"flex":"0 0 20px"},attrs:{"src":"media/svg/misc/snk.svg"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Syarat & Ketentuan")])],1)])]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Manage")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
}]

export { render, staticRenderFns }